<template>
  <vx-card class="edit-cashbox"
           :class="{'main-box top-zero-radius p-2': cashBoxId === 0, 'no-box-shadow': cashBoxId > 0}">
    <div class="edit-cashbox-fields">
      <custom-validate-input class="w-full"
                             :label="`${$t('treasury.cashBoxes.labels.name')}`"
                             :autocomplete="false"
                             align="right"
                             rtl
                             :name="Math.random()"
                             @keydown.enter="sendData"
                             v-model="cash_box.name"/>

      <vs-button id="editCashBoxBTN"
                 class="useral-action-button"
                 @click="sendData()"/>

      <vs-button id="deleteCashBoxBTN"
                 v-if="checkUserPermissions('cash.delete')"
                 class="w-full mt-5"
                 color="danger"
                 @click="$refs.deleteConfirmation.showDialog()">{{ $t('treasury.cashBoxes.labels.delete') }}</vs-button>
    </div>

    <custom-dialog ref="deleteConfirmation"
                   :title="`${$t('treasury.cashBoxes.confirmations.delete.title')}`"
                   :body="`${$t('treasury.cashBoxes.confirmations.delete.body', {cashBox: cash_box.name})}`"
                   @accept="deleteData()"/>
  </vx-card>
</template>

<script>
import axios from 'axios'
import {deleteCashBoxes, editCashBoxes, getCashBox} from '@/http/requests/treasury/cashBoxes'
import CustomDialog from '@/components/customDialog/customDialog'
import {checkUserPermissions} from "../../../../../../assets/js/functions";
import CustomValidateInput from "@/components/customInput/customValidateInput.vue";

export default {
  name: 'editCashBox',
  components: {CustomValidateInput, CustomDialog},
  metaInfo () {
    return {
      title: this.cash_box.name ? this.$t('treasury.cashBoxes.edit.dynamicTitle', {cashBox: this.cash_box.name}) : this.$t('treasury.cashBoxes.edit.title')
    }
  },
  props: {
    cashBoxId: {
      type: Number,
      default: () => { return 0 }
    }
  },
  data () {
    return {
      firstChange: true,
      cash_box: {},
      actions: [
        {
          toolbar: [
            {
              id: 'editCashBoxBTN',
              icon: 'icon-check',
              iconPack: 'feather',
              color: 'success',
              permission: 'cash.update'
            }
          ],
          leftToolbar: [
            {
              id: 'routeBackBTN',
              route: {name: this.$route.name === 'editTreasuryCashBox' ? 'treasuryCashBox' : 'treasuryCashBoxes'},
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      ]
    }
  },
  created () {
    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', 'mx-auto')
    }, 50)

    this.getCashBox()
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    getCashBox () {
      getCashBox(this.cashBoxId ||  this.$route.params.id).then(response => {
        const cashbox = response.data.data
        this.cash_box = {
          id: cashbox.id,
          name: {
            value: cashbox.name,
            isValid: true
          }
        }
        // this.$store.dispatch('setPageTitle', this.$t('treasury.cashBoxes.edit.dynamicTitle', {cashBox: this.cash_box.name}))
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },

    sendData () {
      const cash_box = {
        id: this.cash_box.id,
        name: this.cash_box.name.value
      }
      editCashBoxes(cash_box).then(response => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.cashBoxes.notifications.edit.success'),
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        this.$store.dispatch('removePageChanges')
        this.$router.push({name: 'treasuryCashBoxes'})
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cashBoxes.notifications.edit.error'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      })
    },
    deleteData () {
      deleteCashBoxes(this.cashBoxId || this.$route.params.id).then(response => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.cashBoxes.notifications.delete.success'),
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather',
          color: 'success'
        })

        this.$store.dispatch('removePageChanges')
        this.$router.push({name: 'treasuryCashBoxes'})
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.cashBoxes.notifications.delete.error'),
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            color: 'danger'
          })
        }
      })
    }
  },
  watch: {
    cash_box: {
      handler (val, oldVal) {
        if (!this.firstChange) this.$store.dispatch('setPageChanges')

        if (Object.keys(oldVal).length > 0 && JSON.stringify(val)) {
          this.firstChange = false
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit-cashbox {
    .edit-cashbox-fields {
      max-width: 400px;
    }

    &.in-modal {
      box-shadow: none;

      .vx-card__body {
        padding: 0.5rem;
      }
    }
  }
</style>
